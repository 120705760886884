import store from "@/store";
import { db } from '@/firebase';
import { collection, query, where, getDocs, doc, runTransaction, writeBatch } from "firebase/firestore";
const companyId = store.state.auth.user.companyId;

// blacklist
export const getAllBlockedAttendees = async () => {
    const attendeesPath = `companies/${companyId}/attendees`

    try {
        const q = query(collection(db, attendeesPath), where("disabled", "==", true));
        const querySnapshot = await getDocs(q);

        const items = []
        querySnapshot.forEach((doc) => {
            items.push(doc.id)
        });

        return items;
    } catch (error) {
        throw new Error(error);
    }
}

export const removeBlackList = async (array) => {
    const batch = writeBatch(db);
    const attendeesPath = `companies/${companyId}/attendees`

    try {
        array.forEach(element => {
            const sfRef = doc(db, attendeesPath, String(element));
            batch.update(sfRef, { disabled: false });
        });

        await batch.commit();
    } catch (error) {
        throw new Error(error);
    }
}

export const removeAttendeeBlocked = async (uid) => {
    const sfDocRef = doc(db, `companies/${companyId}/attendees`, uid);

    try {
        await runTransaction(db, async (transaction) => {
            const sfDoc = await transaction.get(sfDocRef);

            if (!sfDoc.exists()) {
                throw "Document does not exist!";
            }

            const isVip = sfDoc.data().vip;


            if (isVip) {
                transaction.update(sfDocRef, { disabled: false });
            } else {
                transaction.delete(sfDocRef);
            }
        });

    } catch (error) {
        throw new Error(error);

    }
}

// vip
export const getAllVipAttendees = async () => {
    const attendeesPath = `companies/${companyId}/attendees`

    try {
        const q = query(collection(db, attendeesPath), where("vip", "==", true));
        const querySnapshot = await getDocs(q);

        const items = []
        querySnapshot.forEach((doc) => {
            items.push({ uid: doc.id, ...doc.data(), loading: false })
        });

        return items;
    } catch (error) {
        throw new Error(error);
    }
}

export const addAttendeeBlocked = async (uid) => {
    const sfDocRef = doc(db, `companies/${companyId}/attendees`, uid);

    try {

        await runTransaction(db, async (transaction) => {
            const sfDoc = await transaction.get(sfDocRef);

            if (sfDoc.exists()) {
                transaction.update(sfDocRef, { disabled: true });
            } else {
                transaction.set(sfDocRef, { disabled: true });
            }
        });

    } catch (error) {
        throw new Error(error);

    }
}

export const addAttendeeVip = async (uid) => {
    const sfDocRef = doc(db, `companies/${companyId}/attendees`, uid);

    try {

        await runTransaction(db, async (transaction) => {
            const sfDoc = await transaction.get(sfDocRef);

            if (sfDoc.exists()) {
                transaction.update(sfDocRef, { vip: true });
            } else {
                transaction.set(sfDocRef, { vip: true });
            }
        });

    } catch (error) {
        throw new Error(error);

    }
}

export const removeAttendeeVip = async (uid) => {
    const sfDocRef = doc(db, `companies/${companyId}/attendees`, uid);

    try {

        await runTransaction(db, async (transaction) => {
            const sfDoc = await transaction.get(sfDocRef);

            if (!sfDoc.exists()) {
                throw "Document does not exist!";
            }

            const isBlocked = sfDoc.data().disabled;


            if (isBlocked) {
                transaction.update(sfDocRef, { vip: false });
            } else {
                transaction.delete(sfDocRef);
            }
        });

    } catch (error) {
        throw new Error(error);

    }
}
