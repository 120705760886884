<template>
  <div class="view-attendees__blocked">
    <!-- Header -->
    <h2 class="px-4 mt-3" v-if="!isMobile">Asistentes Bloqueados</h2>

    <!-- list -->
    <v-list class="transparent">
      <!-- subheader -->
      <v-subheader>
        <span style="width: 15px;">{{ selectedCount }}</span>Asistentes seleccionados
        <v-spacer />
        <v-btn color="primary" @click="selectedAttendees = []" text small>Limpiar Selección</v-btn>
      </v-subheader>
      <!-- attendee -->
      <v-list-item v-for="(attendee, index) in attendees" :key="index">
        <v-list-item-action>
          <v-checkbox v-model="selectedAttendees" :value="attendee" />
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ attendee }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <!-- footer -->
    <v-footer class="py-3" app inset>
      <v-btn @click="dialog = true" :loading="loading" :disabled="loading" color="primary" v-if="selectedCount === 0"
        depressed block>
        Agregar Nuevo
      </v-btn>
      <v-btn @click="removeData" :loading="loading" :disabled="loading" color="primary" v-else depressed block>
        Desbloquear ({{ selectedCount }}) Asistentes
      </v-btn>
    </v-footer>

    <!-- dialog: new -->
    <dialog-searchUser v-model="dialog" @result="onResult" />
  </div>
</template>

<script>
import { getAllBlockedAttendees, removeBlackList } from "@/api/attendees"
import DialogSearchUser from "@/components/attendees/AddBlackList.vue";

export default {
  components: { DialogSearchUser },

  data: () => ({
    dialog: false,
    loading: false,
    attendees: [],
    selectedAttendees: []
  }),

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },

    selectedCount() {
      return this.selectedAttendees.length;
    }
  },

  methods: {
    async fetchData() {
      try {
        this.loading = true;
        this.attendees = await getAllBlockedAttendees();
      } catch (error) {
        console.error('blacklist: ', error)
      } finally {
        this.loading = false;
      }
    },

    async removeData() {
      try {
        this.loading = true;
        await removeBlackList(this.selectedAttendees);
        this.fetchData();
      } catch (error) {
        console.error('blacklist:  ', error)
      } finally {
        this.loading = false;
        this.selectedAttendees = []
      }
    },

    onResult(params) {
      this.attendees.push(params)
    },
  },

  mounted() {
    this.fetchData();
  }
}
</script>